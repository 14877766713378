enum ProductSort {
  Cheapest = 'cheapest',
  MostDiscounted = 'mostDiscounted',
  MostExpensive = 'mostExpensive',
  MostPopular = 'mostPopular',
  MostRated = 'mostRated',
  Newest = 'newest',
  Random = 'random'
}

const { Cheapest, MostDiscounted, MostExpensive, MostPopular, MostRated, Newest, Random } = ProductSort

const nameMapping = new Map([
  [Cheapest, 'Сначала дешёвые'],
  [MostDiscounted, 'По размеру скидки'],
  [MostExpensive, 'Сначала дорогие'],
  [MostPopular, 'Популярные'],
  [MostRated, 'Высокий рейтинг'],
  [Newest, 'Новинки'],
  [Random, 'В случайном порядке']
])

namespace ProductSort {
  export const sortedMembers = [MostPopular, Newest, Cheapest, MostExpensive, MostDiscounted, MostRated]

  export function getName (productSort: ProductSort): string {
    return nameMapping.get(productSort)!
  }
}

export default ProductSort
